<template>
  <div class="Products">
    <v-container fluid class="py-0">
      <!-- **********inicia intro en productos********** -->
      <v-row class="Products__intro" align="center">
        <v-col cols="12" sm="6" class="pb-0">
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="position-relative text-center">
              <!-- arrows -->
              <div class="arrow-right in-products" @click="carouselProducts++">
                <v-img src="@/assets/icons/arrow-slider.svg"></v-img>
              </div>

              <div class="arrow-left in-products" @click="carouselProducts--">
                <v-img src="@/assets/icons/arrow-slider.svg"></v-img>
              </div>
              <!-- /arrows -->

              <v-carousel
                v-model="carouselProducts"
                hide-delimiters
                height="100%"
                cycle
                interval="3000"
                class="Products__intro--carousel"
                v-bind:show-arrows="false"
              >
                <template v-for="(product, pr) in productsEstelar">
                  <v-carousel-item v-bind:key="pr">
                    <v-card
                      color="#ECE8DD"
                      tile
                      elevation="0"
                      :to="'/product/' + product.url"
                    >
                      <template v-if="product.cover != null">
                        <v-img
                          :src="
                            'https://www.cymcms.actstudio.xyz/' +
                            product.cover.path
                          "
                          contain
                          class="Products__image-product"
                        ></v-img>
                      </template>
                    </v-card>
                  </v-carousel-item>
                </template>
              </v-carousel>

              <div class="Products__name text-center mx-auto">
                <span class="continuos-texts-small d-block mx-auto">
                  Meet:
                </span>

                <template v-for="(product, nm) in productsEstelar">
                  <span
                    v-bind:key="nm"
                    v-if="carouselProducts == nm"
                    v-bind:class="{
                      'bree-serif': product.style.name == 'Transitional',
                      pacifico: product.style.name == 'Classic',
                      'montserrat-regular': product.style.name == 'Modern',
                    }"
                    class="titles-extra-big text-center"
                  >
                    {{ product.name }}
                  </span>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6" class="pa-0 position-relative">
          <template v-for="(product, pri) in productsEstelar">
            <template v-if="product.cover_feed != null">
              <v-img
                v-show="carouselProducts == pri"
                v-bind:key="pri"
                v-bind:src="
                  'https://www.cymcms.actstudio.xyz/' + product.cover_feed.path
                "
                class="Products__image-cover-feed"
                position="bottom center"
              >
              </v-img>
            </template>
          </template>

          <div class="Products__div-description">
            <v-row
              justify="center"
              class="fill-height"
              align="center"
              no-gutters
            >
              <v-col cols="10">
                <template v-for="(product, prt) in productsEstelar">
                  <p
                    v-bind:key="prt"
                    class="white--text"
                    v-show="carouselProducts == prt"
                  >
                    <!-- {{ product.description }} -->
                    {{ shorten(product.description) }}
                  </p>
                </template>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <!-- **********/termina intro en productos********** -->
    </v-container>

    <v-container>
      <!-- **********inicia filtros de productos********** -->
      <v-row class="d-none">
        <v-col cols="1">
          <div class="btn-filter" @click="1">
            <v-img
              src="@/assets/icons/filter.svg"
              class="d-inline-block mx-4"
              width="17px"
            ></v-img>
            <span class="continuos-texts-xs-small">Filter By: </span>
          </div>
        </v-col>
      </v-row>

      <v-row class="d-none d-sm-flex">
        <v-col>
          <ul class="Products__filter-bar d-flex not-list-style align-center">
            <li>
              <div class="d-flex" style="align-items: center">
                <div>
                  <v-img src="@/assets/icons/filter.svg"></v-img>
                </div>
                <div>
                  <span class="d-inline-block ml-5 continuos-texts-xs-small"
                    >Filtered by:
                  </span>
                </div>
              </div>
            </li>

            <li class="text-center">
              <v-menu
                nudge-bottom
                open-on-click
                offset-y
                bottom
                content-class="Products__filter-bar--menu"
                origin="center"
                elevation="0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <template v-if="filteredProducts == 'All'">
                    <div
                      class="d-flex justify-center align-center"
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{
                        'bree-serif': filteredProducts == 'Transitional',
                        pacifico: filteredProducts == 'Classic',
                        'montserrat-regular': filteredProducts == 'Modern',
                      }"
                    >
                      <div>
                        <span>style</span>
                      </div>
                      <div class="ml-5">
                        <v-img
                          src="@/assets/icons/arrow.svg"
                          class="d-inline-block"
                        ></v-img>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="filteredProducts != 'All'">
                    <div
                      class="d-flex justify-center align-center"
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{
                        'bree-serif': filteredProducts == 'Transitional',
                        pacifico: filteredProducts == 'Classic',
                        'montserrat-regular': filteredProducts == 'Modern',
                      }"
                    >
                      <div>
                        <span>{{ filteredProducts }}</span>
                      </div>
                      <div class="ml-5">
                        <v-img
                          src="@/assets/icons/arrow.svg"
                          class="d-inline-block"
                        ></v-img>
                      </div>
                    </div>
                  </template>
                </template>

                <v-list>
                  <v-list-item @click="filteredProducts = 'All'">
                    <v-list-item-title class="continuos-texts-4">
                      All
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(category, st) in styles"
                    v-bind:key="st"
                    @click="filteredProducts = category.name"
                  >
                    <v-list-item-title class="continuos-texts-4">{{
                      category.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>

            <li class="text-center">
              <v-menu
                nudge-bottom
                open-on-click
                offset-y
                bottom
                content-class="Products__filter-bar--menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <template v-if="filterDimensions == ''">
                    <div
                      class="d-flex justify-center align-center"
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{
                        'bree-serif': filteredProducts == 'Transitional',
                        pacifico: filteredProducts == 'Classic',
                        'montserrat-regular': filteredProducts == 'Modern',
                      }"
                    >
                      <div>
                        <span>dimensions</span>
                      </div>
                      <div class="ml-5">
                        <v-img
                          src="@/assets/icons/arrow.svg"
                          class="d-inline-block"
                        ></v-img>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="d-flex justify-center align-center"
                      v-bind="attrs"
                      v-on="on"
                      v-bind:class="{
                        'bree-serif': filteredProducts == 'Transitional',
                        pacifico: filteredProducts == 'Classic',
                        'montserrat-regular': filteredProducts == 'Modern',
                      }"
                    >
                      <div>
                        <span>{{ filterDimensions }}</span>
                      </div>
                      <div class="ml-5">
                        <v-img
                          src="@/assets/icons/arrow.svg"
                          class="d-inline-block"
                        ></v-img>
                      </div>
                    </div>
                  </template>
                </template>
                <v-list>
                  <v-list-item @click="filterDimensions = ''">
                    <v-list-item-title class="continuos-texts-4">
                      All
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-for="(category, dm) in dimensions"
                    v-bind:key="dm"
                    @click="filterDimensions = category.name"
                  >
                    <v-list-item-title class="continuos-texts-4">{{
                      category.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>

            <li class="text-center">
              <v-menu
                nudge-bottom
                open-on-click
                offset-y
                bottom
                content-class="Products__filter-bar--menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex justify-center align-center"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div v-if="filteredBack == ''">
                      <span>back</span>
                    </div>
                    <div v-else-if="filteredBack != ''">
                      <span>{{ filteredBack }} </span>
                    </div>
                    <div class="ml-5">
                      <v-img
                        src="@/assets/icons/arrow.svg"
                        class="d-inline-block"
                      ></v-img>
                    </div>
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="filteredBack = ''">
                    <v-list-item-title class="continuos-texts-4">
                      All
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(category, bk) in backs"
                    v-bind:key="bk"
                    @click="filteredBack = category.name"
                  >
                    <v-list-item-title class="continuos-texts-4">{{
                      category.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>

            <li class="text-center">
              <v-menu
                nudge-bottom
                open-on-click
                offset-y
                bottom
                content-class="Products__filter-bar--menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="d-flex justify-center align-center"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <div v-if="filteredBarstool == ''">
                      <span>Barstool Type</span>
                    </div>
                    <div v-if="filteredBarstool != ''">
                      <span>{{ filteredBarstool }}</span>
                    </div>
                    <div class="ml-5">
                      <v-img
                        src="@/assets/icons/arrow.svg"
                        class="d-inline-block"
                      ></v-img>
                    </div>
                  </div>
                </template>
                <v-list>
                  <v-list-item @click="filteredBarstool = ''">
                    <v-list-item-title class="continuos-texts-4">
                      All
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-for="(category, st) in stationaries"
                    v-bind:key="st"
                    @click="filteredBarstool = category.name"
                  >
                    <v-list-item-title class="continuos-texts-4">{{
                      category.name
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </li>
          </ul>
        </v-col>
      </v-row>
      <!-- **********/termina filtros de productos********** -->

      <template v-if="$route.name == 'Products'">
        <Page :products="filter" :page="parseInt(1)" />
      </template>

      <template v-if="$route.name == 'ProductsPage'">
        <Page :products="filter" :page="parseInt($route.params.page)" />
      </template>

      <template>
        <div class="arrow-more-content position-relative">
          <div class="text-center arrow-more-content__pagination">
            <router-link
              :to="'/Products/' + (parseInt($route.params.page) + -1)"
              v-if="parseInt($route.params.page) + 1 >= 2"
              class="not-text-decoration"
            >
              <v-icon class="arrow-more-content__left"> mdi-arrow-left </v-icon>
            </router-link>

            <template v-for="(page, p) in Math.ceil(filter.length / 12)">
              <v-btn
                fab
                :key="p"
                :to="'/Products/' + parseInt(page)"
                elevation="0"
                class="arrow-more-content__pagination--btn"
                :class="{'v-btn--active': $route.name == 'Products' && p == 0}"

              >
                {{ page }}
              </v-btn>
            </template>

            <router-link
              :to="'/Products/' + (parseInt($route.params.page) + 1)"
              class="not-text-decoration"
            >
              <v-icon
                v-if="parseInt($route.params.page) + 1 < filter.length / 12 + 1"
                class="arrow-more-content__right"
              >
                mdi-arrow-right
              </v-icon>
            </router-link>
          </div>
        </div>
      </template>

      <!-- **********inicia productos********** -->

      <!-- **********/termina productos********** -->
    </v-container>
  </div>
</template>
<script>
import Page from "../components/Globals/Page.vue";
export default {
  data() {
    return {
      products: [],
      productsEstelar: [],
      carouselProducts: 0,
      styles: [],
      dimensions: [],
      backs: [],
      stationaries: [],
      descriptionText: "",
      filteredProducts: "",
      filterDimensions: "",
      filteredBack: "",
      filteredBarstool: "",
    };
  },
  components: {
    Page,
  },
  computed: {
    filter: function () {
      var aux = this.products;

      var aux_this = this;

      if (this.filteredProducts != "All") {
        aux = aux.filter(function (product) {
          return product.style.name == aux_this.filteredProducts;
        });
      }

      if (this.filterDimensions != "") {
        aux = aux.filter(function (product) {
          return product.dimensions.name == aux_this.filterDimensions;
        });
      }
      if (this.filteredBack != "") {
        aux = aux.filter(function (product) {
          return product.back.name == aux_this.filteredBack;
        });
      }

      if (this.filteredBarstool != "") {
        aux = aux.filter(function (product) {
          return product.stationary.name == aux_this.filteredBarstool;
        });
      }

      return aux;
    },
  },
  created() {
    if (this.$route.query.name != undefined) {
      this.filteredProducts = this.$route.query.name;
    } else {
      this.filteredProducts = "All";
    }
  },

  mounted() {
    //fetch para los productos

    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/products?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.products = res.entries));
    // .then(this.products.description = this.descriptionText);

    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/products?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {
            estelar: true,
          },
          sort: { _o: 1 },
          populate: 2,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.productsEstelar = res.entries))
      .then((this.productsEstelar.description = this.descriptionText));

    //fetch para style
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/style?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.styles = res.entries));

    //fetch para dimentions
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/dimensions?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.dimensions = res.entries));

    //fetch para dimentions
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/back?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.backs = res.entries));

    //fetch para stationary
    fetch(
      "https://www.cymcms.actstudio.xyz/api/collections/get/stationary?token=aa5f1f62f706c89e9dae0ea5e2400a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.stationaries = res.entries));

    //emite el valor para cambiar el valor del logo
    this.$emit("update", 0);
  },
  methods: {
    shorten: function (txt) {
      if (txt.length > 100 && window.innerWidth < 600) {
        return txt.substr(0, 110) + "...";
      }
      if (
        txt.length > 100 &&
        window.innerWidth > 600 &&
        window.innerWidth < 960
      ) {
        return txt.substr(0, 90) + "...";
      }
      return txt;
    },
  },
};
</script>